import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-interior-layout',
    templateUrl: './interior-layout.component.html',
    styleUrls: ['./interior-layout.component.scss'],
})
export class InteriorLayoutComponent {
    constructor() {}
}
