import { createAction, props } from '@ngrx/store';
import { AddFloorPlanDto, FloorPlan } from '@map/models';
import { Update } from '@ngrx/entity';
import { UpdateFloorPlanDto } from '@map/models/update-floor-plan-dto.model';

export const getAll = createAction('[Map] Get All FloorPlans');
export const getAllSuccess = createAction('[FloorPlan Effect] Get All FloorPlans Success', props<{ floorPlans: FloorPlan[] }>());
export const getAllFailed = createAction('[FloorPlan Effect] Get All FloorPlans Failed');

export const get = createAction('[Map] Get FloorPlan', props<{ id: number }>());
export const getSuccess = createAction('[FloorPlan Effect] Get FloorPlan Success', props<{ floorPlan: FloorPlan }>());
export const getFailed = createAction('[FloorPlan Effect] Get FloorPlan Failed');

export const search = createAction('[FloorPlan Search Bar] Search FloorPlans', props<{ term: string }>());
export const searchSuccess = createAction('[FloorPlan Effect] Search FloorPlans Success', props<{ searchResults: FloorPlan[] }>());
export const searchFailed = createAction('[FloorPlan Effect] Search FloorPlans Failed');

export const clearSearchResults = createAction('[FloorPlan Effect] Clear Search Results');

export const setSearchTerm = createAction('[FloorPlan Search Bar] Search Term Updated', props<{ term: string }>());

export const select = createAction('[Floor Plan Form] Select FloorPlan', props<{ id: number }>());
export const selectSuccess = createAction('[FloorPlan Effect] Select FloorPlan Success', props<{ floorPlan: FloorPlan }>());
export const selectFailed = createAction('[FloorPlan Effect] Select FloorPlan Failed');

export const deselect = createAction('[FloorPlan Details] Deselect FloorPlan');

export const add = createAction('[FloorPlan Form] Add FloorPlan', props<{ addFloorPlanDto: AddFloorPlanDto }>());
export const addSuccess = createAction('[FloorPlan Effect] Add FloorPlan Success', props<{ floorPlan: FloorPlan }>());
export const addFailed = createAction('[FloorPlan Effect] Add FloorPlan Failed');

export const update = createAction('[FloorPlan Form] Update FloorPlan', props<{ updateFloorPlanDto: UpdateFloorPlanDto }>());
export const updateSuccess = createAction('[FloorPlan Effect] Update FloorPlan Success', props<{ floorPlan: Update<FloorPlan> }>());
export const updateFailed = createAction('[FloorPlan Effect] Update FloorPlan Failed');

export const getImageUrls = createAction('[FloorPlan Effect] Get Image Urls', props<{ floorPlanIds: number[] }>());
export const getImageUrlsSuccess = createAction('[FloorPlan Effect] Get Image Urls Success', props<{ floorPlans: FloorPlan[] }>());
export const getImageUrlsFailed = createAction('[FloorPlan Effect] Get Image Urls Failed');

export const remove = createAction('[FloorPlan List] Remove FloorPlan', props<{ id: number }>());
export const removeSuccess = createAction('[FloorPlan Effect] Remove FloorPlan Success', props<{ id: number }>());
export const removeFailed = createAction('[FloorPlan Effect] Remove FloorPlan Failed');

export const editShape = createAction('[FloorPlan Form] Edit FloorPlan Shape', props<{ floorPlan: FloorPlan }>());
export const cancelEditShape = createAction('[Map] Cancel Edit FloorPlan Shape');
