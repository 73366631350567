import { Region, ActiveType } from '@map/models';
import { createSelector } from '@ngrx/store';
import { RootState } from '..';
import { adapter, RegionState } from './region.state';

export const selectFeature = (state: RootState) => state.regions;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state: RegionState) => state.loading);

export const selectLoaded = createSelector(selectFeature, (state: RegionState) => state.loaded);

export const selectByBuildingId = (buildingId: number) =>
    createSelector(selectFeature, (state: RegionState) => {
        return state.buildingRegions.filter((x: Region) => x.buildingId === buildingId)[0];
    });

export const selectActiveByBuildingId = (buildingId: number) =>
    createSelector(selectFeature, (state: RegionState) => {
        const buildingRegions = state.buildingRegions.filter(
            (x: Region) => x.buildingId === buildingId && x.activeType === ActiveType.Active && x.buildingFloorId == null
        );
        return buildingRegions[0];
    });

export const selectActiveByFloorId = (floorId: number) =>
    createSelector(selectAll, (regions: Region[]) => {
        return regions.filter((x: Region) => x.activeType === ActiveType.Active && x.buildingFloorId == floorId)?.[0];
    });

export const selectEditingShape = createSelector(selectFeature, (state: RegionState) => state.editShape);

export const selectSearchResults = createSelector(selectFeature, (state: RegionState) => state.searchResults);

export const selectSearchTerm = createSelector(selectFeature, (state: RegionState) => state.searchTerm);

export const selectBuildingRegions = createSelector(selectFeature, (state: RegionState) => state.buildingRegions);

export const selectActiveRegions = createSelector(selectAll, (state: Region[]) =>
    state.filter((x: Region) => x.activeType === ActiveType.Active)
);

export const selectSelectedRegion = createSelector(selectFeature, (state: RegionState) => state.selected);

export const selectOccupancy = createSelector(selectFeature, (state: RegionState) => state.occupancy);

export const selectOccupancyList = createSelector(selectFeature, (state: RegionState) => state.occupancyList);
