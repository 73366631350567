import { Floor } from '@map/models';
import { createSelector } from '@ngrx/store';
import { RootState } from '..';
import { adapter, FloorState } from './floor.state';

export const selectFeature = (state: RootState) => state.floors;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectFeature);

export const selectAllOccupied = createSelector(selectAll, (state: Floor[]) => {
    return state.filter((x: Floor) => x.occupied);
});

export const selectLoading = createSelector(selectFeature, (state: FloorState) => state.loading);

export const selectLoaded = createSelector(selectFeature, (state: FloorState) => state.loaded);

export const selectSelectedFloor = createSelector(selectFeature, (state: FloorState) => state.selected);

export const selectSelectedFloorNumber = createSelector(selectFeature, (state: FloorState) => state.selectedFloorNumber);

export const selectCloseFormModal = createSelector(selectFeature, (state: FloorState) => state.closeFormModal);

export const selectAllUnique = createSelector(selectAll, (state: Floor[]) => {
    return state
        .map((item: Floor) => item.floorId)
        .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index)
        .sort();
});

export const selectByBuildingId = ({ buildingId, onlyOccupied = true }: { buildingId: number; onlyOccupied?: boolean }) =>
    createSelector(selectAll, (state: any) => {
        return state
            .filter((x: Floor) => x.buildingId === buildingId && (onlyOccupied ? x.occupied : true))
            .sort((a: Floor, b: Floor) => a.floorLabel.localeCompare(b.floorLabel));
    });

export const selectByLocationId = ({
    locationId,
    onlyOccupied = true,
    filterDuplicates = false,
}: {
    locationId: number;
    onlyOccupied?: boolean;
    filterDuplicates?: boolean;
}) =>
    createSelector(selectAll, (state: any) => {
        if (filterDuplicates) {
            return state
                .filter((x: Floor) => x.building?.locationId === locationId && (onlyOccupied ? x.occupied : true))
                .filter((floor: Floor, index: any, self: any) => index === self.findIndex((f: Floor) => f.floorId === floor.floorId))
                .sort((a: Floor, b: Floor) => a.floorLabel.localeCompare(b.floorLabel));
        }
        return state
            .filter((x: Floor) => x.building?.locationId === locationId && (onlyOccupied ? x.occupied : true))
            .sort((a: Floor, b: Floor) => a.floorLabel.localeCompare(b.floorLabel));
    });

export const selectFloorsByLocationId = ({
    locationId,
    onlyOccupied = true,
    filterDuplicates = false,
}: {
    locationId: number;
    onlyOccupied?: boolean;
    filterDuplicates?: boolean;
}) =>
    createSelector(selectAll, (state: any) => {
        if (filterDuplicates) {
            return state
                .filter((x: Floor) => x.building?.locationId === locationId && (onlyOccupied ? x.occupied : true))
                .filter((floor: Floor, index: any, self: any) => index === self.findIndex((f: Floor) => f.floorId === floor.floorId))
                .sort((a: Floor, b: Floor) => a.floorLabel.localeCompare(b.floorLabel));
        }
        return state
            .filter((x: Floor) => x.building?.locationId === locationId && (onlyOccupied ? x.occupied : true))
            .sort((a: Floor, b: Floor) => a.floorLabel.localeCompare(b.floorLabel));
    });
