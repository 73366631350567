// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-view {
  flex: 1;
  height: 100%;
}
@media screen and (max-width: 576px) {
  .application-view {
    padding-bottom: 60px;
  }
}

.map-interface-container {
  display: flex;
  flex-grow: 1;
  position: absolute;
  width: 100%;
  pointer-events: none;
  height: 100vh;
}

:host(app-map-layout) {
  display: flex;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/map/layouts/map-layout/map-layout.component.scss","webpack://./src/styles/abstracts/_variables.scss"],"names":[],"mappings":"AACA;EACI,OAAA;EACA,YAAA;AAAJ;AACI;EAHJ;IAIQ,oBCiBsB;EDf5B;AACF;;AAAA;EACI,aAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,oBAAA;EACA,aAAA;AAGJ;;AADA;EACI,aAAA;EACA,YAAA;AAIJ","sourcesContent":["@import '../../../../styles/abstracts/variables';\n.application-view {\n    flex: 1;\n    height: 100%;\n    @media screen and (max-width: $breakPoint-sm) {\n        padding-bottom: $left-nav-item-height-mobile;\n    }\n}\n.map-interface-container {\n    display: flex;\n    flex-grow: 1;\n    position: absolute;\n    width: 100%;\n    pointer-events: none;\n    height: 100vh;\n}\n:host(app-map-layout) {\n    display: flex;\n    flex-grow: 1;\n}\n","// Breakpoints\n$breakPoint-sm: 576px;\n$breakPoint-md: 768px;\n$breakPoint-lg: 992px;\n$breakPoint-xl: 1200px;\n\n// Inputs\n$input-width: 480px;\n$input-height: 36px;\n$checkbox-size: 22px;\n$checkbox-size-small: 22px;\n\n$border-radius: 6px;\n\n// Box shadow\n$panel-box-shadow: 0 2px 6px 1px #000;\n\n// Left Nav\n$left-nav-width: var(--left-nav-width);\n$left-nav-width-expanded: var(--left-nav-width-expanded);\n$left-nav-active-border-width: 4px;\n$left-nav-item-height: 44px;\n$left-nav-item-height-mobile: 60px;\n\n// Overview panel\n$overview-panel-width: 0;\n$overview-panel-width-expanded: 310px;\n$overview-panel-active-border-width: 4px;\n$overview-panel-item-height: 44px;\n\n// Analytics panel\n$analytics-panel-height: 36px;\n$analytics-panel-height-expanded: 140px;\n\n// Alert details\n$alert-details-panel-width: 400px;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
