import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import { Building, BuildingAnalytics } from '@map/models';
import { BaseService } from '@shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import Helpers from '@core/utils/helpers';
import { BuildingClassification } from '@map/interfaces';

@Injectable({
    providedIn: 'root',
})
export class BuildingService extends BaseService {
    private minimizeDetails: Subject<void> = new Subject();
    minimizeDetails$ = this.minimizeDetails.asObservable();

    constructor(private httpClient: HttpClient) {
        super('Buildings', httpClient);
    }

    getBuildingClassifications(): Observable<BuildingClassification[]> {
        return this.http.get(`${this.apiUrl}/Classifications`).pipe(
            map((response: any) => {
                if (!response || !response.$values) return [];
                return response?.$values.map((responseJson: any) => new BuildingClassification(responseJson));
            }),
            catchError((error) => {
                return this.handleError(error);
            }),
            share()
        );
    }

    getAnalytics(buildingId: number, startDate?: Date, endDate?: Date): Observable<BuildingAnalytics> {
        let queryParams = '';
        if (startDate && endDate) {
            queryParams += `?startInstant=${Helpers.formatDateToJSON(startDate)}&endInstant=${Helpers.formatDateToJSON(endDate)}`;
        }
        return this.http.get(`${this.apiUrl}/${buildingId}/Analytics${queryParams}`).pipe(
            map((response: any) => new BuildingAnalytics(response)),
            catchError((error) => {
                return this.handleError(error);
            }),
            share()
        );
    }
    updatePolygon(id: number, polygon?: number[][]): Observable<void> {
        let data = '""';
        if (polygon) {
            data = `"${JSON.stringify(polygon)}"`;
        }
        return this.http.put(`${this.apiUrl}/${id}/PolygonJson`, data, { headers: this.headers }).pipe(
            catchError((error) => {
                return this.handleError(error);
            }),
            share()
        );
    }
    mapResponseToObject<T>(response: any): T {
        const building = new Building(response) as any;
        return building;
    }
    mapArrayResponseToObject<T>(response: any): T {
        return response.map((responseJson: any) => new Building(responseJson));
    }

    minimizeDetailsPanel() {
        this.minimizeDetails.next();
    }
}
