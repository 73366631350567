export const environment = {
    production: true,
    apiUrl: 'https://unh-api.airia20.com/api/',
    // Client timezone. Valid values are listed here: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    organizationName: 'UNH',
    timeZone: 'America/New_York',
    timeZoneOffsetUTC: -(new Date().getTimezoneOffset() / 60),
    // The ID of the default Location to display
    defaultLocationId: 1,
    // The ID of the region that encompasses all regions
    entireRegionId: 1,
    // MapBox
    mapboxAccessToken: 'pk.eyJ1IjoibWlrZWFpcmlhIiwiYSI6ImNrbnF1cnNnaTBnaG8ydm15dXRuOGVodDgifQ.LbEjDzKyUje8uRE220hoqQ',
    mapboxStyle: 'mapbox://styles/mikeairia/cls0nz5tg009o01q2b1wzaokq',
    zoomLevel: 16,
    hidePOIs: false,
    hideStreetLabels: false,
    // Azure B2C Configuration
    b2cClientId: '1df9bff1-b200-4be2-bb5e-dc9086324a1c',
    b2cNameSignUpSignIn: 'B2C_1_signupin-unh',
    b2cNameEditProfile: 'B2C_1_profile',
    b2cNameForgitPassword: 'B2C_1_profile',
    b2cAuthorityDomain: 'airiaauth01.b2clogin.com',
    b2cAuthoritySignUpSignIn: 'https://airiaauth01.b2clogin.com/airiaauth01.airia20.com/B2C_1_signupin-unh',
    b2cAuthorityEditProfile: 'https://airiaauth01.b2clogin.com/airiaauth01.airia20.com/B2C_1_profile',
    b2cAuthorityForgotPassword: 'https://airiaauth01.b2clogin.com/airiaauth01.airia20.com/b2c_1_pwreset',
    b2cScopes: ['https://airiaauth01.airia20.com/1df9bff1-b200-4be2-bb5e-dc9086324a1c/demo.read'],
    b2cApiUrl: 'https://unh-api.airia20.com',
    disableAlertsKnob: false,
    enableAlertsKnobTime: new Date('2022-06-01').getTime(),
    // Floor plans
    floorPlanZoomLevel: 17,
    floorPlans: [],
};
