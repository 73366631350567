import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Report } from 'src/app/report/models';

export interface ReportState extends EntityState<Report> {
    selected: Report | null;
    preview: Report | null;
    loaded: boolean;
    loading: boolean;
}
export const adapter = createEntityAdapter<Report>();
export const initialState: ReportState = adapter.getInitialState({
    selected: null,
    preview: null,
    loaded: false,
    loading: false,
});
