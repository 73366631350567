import { IBase } from '@shared/interfaces';
import { ResponseList } from '@shared/models';
import { AccessPoint } from './access-point.model';
import { Building } from './building.model';
import { FloorPlan } from './floor-plan.model';
import { Region } from './region.model';

export class Floor implements IBase {
    id: number;
    name?: string;
    building!: Building;
    buildingId!: number;
    floorId!: number | string;
    floorMaxOccupancy!: number;
    createdAt!: Date;
    accessPoints?: AccessPoint[];
    floorLabel: string;
    occupied: boolean;
    // fullName: string;
    region?: Region;
    floorPlans?: FloorPlan[];

    constructor(args: {
        building: Building;
        buildingFloorId: number;
        buildingId: number;
        floorId: number | string;
        floorMaxOccupancy: number;
        createdAt: Date;
        accessPoints: ResponseList<AccessPoint>;
        name?: string;
        occupied: boolean;
        floorLabel?: string;
    }) {
        this.id = args.buildingFloorId;
        this.building = new Building({ ...args.building, buildingId: args.buildingId } as any);
        this.buildingId = args.buildingId;
        this.floorId = args.floorId?.toString();
        this.floorMaxOccupancy = args.floorMaxOccupancy;
        this.createdAt = args.createdAt;
        this.name = args.name;
        this.floorLabel = args.floorLabel || '';
        this.occupied = args.occupied;
        // this.fullName = `${args.name ? args.name + ' ' : ''}${args.floorId}${args.floorLabel ? ' ' + args.floorLabel : ''}`;
        if (args.accessPoints?.$values) {
            this.accessPoints = args.accessPoints.$values.map((responseJson: any) => new AccessPoint(responseJson));
        }
    }
}
