import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Occupancy, FloorPlan } from '@map/models';

export interface FloorPlanState extends EntityState<FloorPlan> {
    selected: FloorPlan | null;
    searchResults: FloorPlan[] | null;
    searchTerm: string;
    editShape: boolean;
    loaded: boolean;
    loading: boolean;
    occupancy: Occupancy | null;
}
export const adapter = createEntityAdapter<FloorPlan>();
export const initialState: FloorPlanState = adapter.getInitialState({
    selected: null,
    searchResults: [],
    searchTerm: '',
    loaded: false,
    editShape: false,
    loading: false,
    occupancy: null,
});
