import { createReducer, on } from '@ngrx/store';
import { adapter, initialState } from './floor-plan.state';
import * as Actions from './floor-plan.actions';

export const floorPlanReducer = createReducer(
    initialState,
    on(Actions.getAll, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getAllSuccess, (state, { floorPlans }) => ({
        ...adapter.setAll(floorPlans, state),
        loading: false,
        loaded: true,
    })),
    on(Actions.getAllFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.setSearchTerm, (state, { term }) => {
        return {
            ...state,
            searchTerm: term,
        };
    }),
    on(Actions.search, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.searchSuccess, (state, { searchResults }) => {
        return {
            ...state,
            searchResults,
            loading: false,
        };
    }),
    on(Actions.searchFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.clearSearchResults, (state) => {
        return {
            ...state,
            searchResults: null,
        };
    }),
    on(Actions.select, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.selectSuccess, (state, { floorPlan }) => ({
        ...state,
        selected: { ...floorPlan },
        loading: false,
    })),
    on(Actions.selectFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.deselect, (state) => {
        return {
            ...state,
            selected: null,
            showOverview: false,
            showDetails: false,
        };
    }),
    on(Actions.add, (state) => {
        return {
            ...state,
            loading: true,
            showOverview: false,
        };
    }),
    on(Actions.addSuccess, (state, { floorPlan }) => {
        console.log('addSuccess reducer', floorPlan);
        return adapter.addOne(floorPlan, {
            ...state,
            editShape: false,
            loading: false,
        });
    }),
    on(Actions.addFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.remove, (state) => {
        return {
            ...state,
            loading: true,
            showOverview: false,
        };
    }),
    on(Actions.removeSuccess, (state, { id }) => {
        return adapter.removeOne(id, {
            ...state,
            loading: false,
        });
    }),
    on(Actions.removeFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.update, (state) => {
        return {
            ...state,
            loading: true,
            showOverview: false,
        };
    }),
    on(Actions.updateSuccess, (state, { floorPlan }) => {
        return adapter.updateOne(floorPlan, {
            ...state,
            loading: false,

            editShape: false,
        });
    }),
    on(Actions.updateFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.editShape, (state, { floorPlan }) => {
        return {
            ...state,
            loading: true,
            editShape: true,
        };
    }),
    on(Actions.cancelEditShape, (state) => {
        return {
            ...state,
            editShape: false,
        };
    }),
    on(Actions.get, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getSuccess, (state, { floorPlan }) => {
        return adapter.upsertOne(floorPlan, {
            ...state,
            loading: false,
        });
    }),
    on(Actions.getFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.getImageUrls, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getImageUrlsSuccess, (state, { floorPlans }) => {
        return adapter.upsertMany(floorPlans, {
            ...state,
            loading: false,
        });
    }),
    on(Actions.getImageUrlsFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    })
    // on(Actions.updateRegionPolygon, Actions.updateRegionPolygonMap, (state) => {
    //     return {
    //         ...state,
    //         loading: true,
    //     };
    // }),
    // on(Actions.updateRegionPolygonSuccess, (state, { region }) => {
    //     return adapter.upsertOne(region, {
    //         ...state,
    //         selected: region,
    //         editShape: false,
    //         loading: false,
    //
    //     });
    // }),
    // on(Actions.updateRegionPolygonFailed, (state) => {
    //     return {
    //         ...state,
    //         editShape: false,
    //         loading: false,
    //     };
    // })
);
