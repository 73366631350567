import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { CreateReportInput } from 'src/app/report/models';
import { Report } from 'src/app/report/models/report.model';

export const getAll = createAction('[Map] Get All Reports');
export const getAllSuccess = createAction('[Report Effect] Get All Reports Success', props<{ reports: Report[] }>());
export const getAllFailed = createAction('[Report Effect] Get All Reports Failed');

export const getByUserId = createAction('[Map] Get Reports by User Id', props<{ userId: number }>());
export const getByUserIdSuccess = createAction('[Report Effect] Get Reports by User Id Success', props<{ reports: Report[] }>());
export const getByUserIdFailed = createAction('[Report Effect] Get Reports by User Id Failed');

export const create = createAction('[Report Form] Create Report Request', props<{ createReportInput: CreateReportInput }>());
export const createSuccess = createAction('[Report Effect] Create Report Success', props<{ report: Report }>());
export const createFailed = createAction('[Report Effect] Create Report Failed');

export const generatePreview = createAction('[Report Form] Generate Report Request', props<{ createReportInput: CreateReportInput }>());
export const generatePreviewSuccess = createAction('[Report Effect] Generate Report Success', props<{ report: Report }>());
export const generatePreviewFailed = createAction('[Report Effect] Generate Report Failed');

export const update = createAction('[Report Form] Update Report', props<{ report: Update<Report> }>());
export const updateSuccess = createAction('[Report Effect] Update Report Success', props<{ report: Update<Report> }>());
export const updateFailed = createAction('[Report Effect] Update Report Failed');

export const select = createAction('[Map Search Bar] Select Report', props<{ id: number }>());
export const selectSuccess = createAction('[Report Effect] Select Report Success', props<{ report: Report }>());
export const selectFailed = createAction('[Report Effect] Select Report Failed');

export const deselect = createAction('[Report Table] Deselect Report');
