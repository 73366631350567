import { Floor } from './floor.model';

export class FloorPlan {
    id!: number;
    title?: string;
    description?: string;
    locationId!: number;
    buildingId?: number;
    floorId?: number;
    floor?: Floor;
    buildingFloorId?: number;
    minZoom?: number = 0;
    maxZoom?: number = 0;
    imageUrl?: string;
    coordinates?: number[][];
    floorPlanImage?: File;

    constructor(args: {
        $id: string;
        floorPlanId: number;
        locationId: number;
        title?: string;
        description?: string;
        floorId?: number;
        buildingFloorId?: number;
        minZoom?: number;
        maxZoom?: number;
        imageUrl?: string;
        coordinates?: number[][];
    }) {
        this.id = args.floorPlanId;
        this.description = args.description;
        this.locationId = args.locationId;
        this.title = args.title;
        this.floorId = args.floorId;
        this.buildingFloorId = args.buildingFloorId;
        this.minZoom = args.minZoom;
        this.maxZoom = args.maxZoom;
        this.imageUrl = args.imageUrl;
        this.coordinates = args.coordinates;

        // if (args.coordinates) {
        //     this.coordinates = JSON.parse(args.coordinates);
        // }
    }
}
