import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Region, Occupancy, Building } from '@map/models';
import { forkJoin, of } from 'rxjs';
import * as RegionActions from './region.actions';
import * as RegionSelectors from './region.selectors';
import * as BuildingSelectors from './../building/building.selectors';
import { RegionService } from '@map/services/region.service';
import { RootState } from '..';
import { Store } from '@ngrx/store';
import { BuildingService } from '@map/services/building.service';

@Injectable({
    providedIn: 'root',
})
export class RegionEffects {
    constructor(
        private actions$: Actions,
        private store: Store<RootState>,
        private regionService: RegionService,
        private buildingService: BuildingService
    ) {}

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.getAll),
            withLatestFrom(this.store.select(RegionSelectors.selectLoaded), this.store.select(RegionSelectors.selectAll)),
            switchMap(([_, loaded, regions]) => {
                if (loaded) {
                    return of(RegionActions.getAllSuccess({ regions }));
                } else {
                    return this.regionService.getAll<Region[]>().pipe(
                        map((regions: Region[]) => RegionActions.getAllSuccess({ regions })),
                        catchError(() => of(RegionActions.getAllFailed()))
                    );
                }
            })
            // switchMap(() => this.buildingService.getAll<Building[]>()),
            // switchMap((buildings) => {
            //     return this.regionService.getAll<Region[]>('+BuildingFloor').pipe(
            //         map((regions: any) => {
            //             regions.forEach((region: any) => {
            //                 this.assignBuildingDetailsToRegion(buildings, region);
            //             });
            //             return regions;
            //             // return regions.filter((region: any) => region.buildingLatitude);
            //         }),
            //         map((regions: Region[]) => RegionActions.getAllSuccess({ regions })),
            //         catchError(() => of(RegionActions.getAllFailed()))
            //     );
            // })
        )
    );

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.search),
            withLatestFrom(this.store.select(RegionSelectors.selectActiveRegions)),
            switchMap(([{ term }, regions]) => {
                const searchResults = regions.filter((entity) => entity.regionName.toLowerCase().includes(term.toLowerCase()));
                return of(
                    RegionActions.searchSuccess({
                        searchResults,
                    })
                );
            }),
            catchError(() => of(RegionActions.searchFailed()))
        )
    );

    getBuildingRegions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.getBuildingRegions),
            mergeMap(() =>
                this.regionService.getAll<Region[]>('?buildingsOnly=true').pipe(
                    map((regions: Region[]) => RegionActions.getBuildingRegionsSuccess({ regions })),
                    catchError(() => of(RegionActions.getBuildingRegionsFailed()))
                )
            )
        )
    );

    getOccupancy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.getOccupancy),
            mergeMap(({ id, year, month, day }) =>
                this.regionService.getOccupancy(id, year, month, day).pipe(
                    map((occupancy: Occupancy) => RegionActions.getOccupancySuccess({ occupancy })),
                    catchError(() => of(RegionActions.getOccupancyFailed()))
                )
            )
        )
    );

    getOccupancyRange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.getOccupancyRange),
            mergeMap(({ id, from, to }) =>
                this.regionService.getOccupancyRange(id, from, to).pipe(
                    map((occupancy: Occupancy) => RegionActions.getOccupancyRangeSuccess({ occupancy })),
                    catchError(() => of(RegionActions.getOccupancyRangeFailed()))
                )
            )
        )
    );

    select$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.select),
            withLatestFrom(this.store.select(BuildingSelectors.selectAll)),
            mergeMap(([{ id }, buildings]) => {
                if (buildings?.length === 0) {
                    return this.buildingService.getAll<Building[]>().pipe(map((buildings2) => ({ id, buildings: buildings2 })));
                }
                return of({ id, buildings });
            }),
            switchMap(({ id, buildings }) => {
                return this.regionService.get<Region>(id, '+BuildingFloor').pipe(
                    tap((region) => this.assignBuildingDetailsToRegion(buildings, region)),
                    map((region) => RegionActions.selectSuccess({ region }))
                );
            }),
            catchError(() => of(RegionActions.selectFailed()))
        )
    );

    add$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.add),
            mergeMap(({ region }) => {
                return this.regionService.create<Region>(region).pipe(
                    map((id: any) => {
                        // Copy the old region object and add the id
                        const newRegion = { ...region };
                        newRegion.id = id;
                        newRegion.regionId = id;
                        return RegionActions.addSuccess({ region: newRegion });
                    })
                );
            }),
            catchError((err) => {
                console.log(err);
                return of(RegionActions.addFailed());
            })
        )
    );

    remove$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.remove),
            mergeMap(({ id }) =>
                this.regionService.delete(id).pipe(
                    map(() => {
                        return RegionActions.removeSuccess({ id });
                    }),
                    catchError((error) => of(RegionActions.removeFailed()))
                )
            )
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.update),
            mergeMap(({ region }) =>
                this.regionService.update<Region>(region.id, region.changes).pipe(
                    map((updatedRegion: Region) => RegionActions.updateSuccess({ region })),
                    catchError((error) => of(RegionActions.updateFailed()))
                )
            )
        )
    );

    updatePolygon$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.updateRegionPolygon, RegionActions.updateRegionPolygonMap),
            withLatestFrom(this.store.select(RegionSelectors.selectEntities)),
            switchMap(([{ id, polygon }, regions]) => {
                const region = regions[id];
                if (!region) {
                    throw new Error(`Unable to find region with ID of ${id}`);
                }
                return of({ region, polygon });
            }),
            mergeMap(({ region, polygon }) => {
                return this.regionService.updatePolygon(region.id, polygon).pipe(
                    map(() => {
                        // Region object is readonly, so we need to create a new one to update the polygon
                        const newRegion = { ...region };
                        newRegion.regionPolygon = polygon;
                        return RegionActions.updateRegionPolygonSuccess({
                            region: newRegion,
                        });
                    })
                );
            }),
            catchError((err) => {
                console.log(err);
                return of(RegionActions.updateRegionPolygonFailed());
            })
        )
    );

    getOccupancyFromReportData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegionActions.getFromReportData),
            mergeMap(({ reportData }) =>
                forkJoin(
                    reportData.map((data) =>
                        this.regionService
                            .getOccupancyRange(data.regionId, data.dateRange[0], data.dateRange[1], false, data.floorId)
                            .pipe(tap((occupancy) => (occupancy.buildingName = data.buildingName)))
                    )
                ).pipe(
                    map((occupancy) => RegionActions.getFromReportDataSuccess({ occupancy })),
                    catchError(() => of(RegionActions.getFromReportDataFailed()))
                )
            )
        )
    );

    assignBuildingDetailsToRegion(buildings: Building[], region: Region): Region {
        try {
            if (region.buildingFloor?.buildingId) {
                const buildingDict = Object.assign({}, ...buildings.map((building) => ({ [building.id]: building })));
                if (!buildingDict[region.buildingFloor?.buildingId]) {
                    throw new Error(
                        `Unable to find building with ID of ${region.buildingFloor?.buildingId} for Region with regionId: ${region.regionId}`
                    );
                }
                region.buildingLatitude = buildingDict[region.buildingFloor?.buildingId].coordLatitude;
                region.buildingLongitude = buildingDict[region.buildingFloor?.buildingId].coordLongitude;
                region.buildingName = region.buildingFloor?.buildingId
                    ? buildingDict[region.buildingFloor?.buildingId].buildingName
                    : 'n/a';
            }
        } catch (err) {
            console.error(err);
        }
        return region;
    }
}
