import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Building, BuildingAnalytics } from '@map/models';
import { BuildingSortType } from '@map/enums/building-sort-type.enum';
import { BuildingClassification } from '@map/interfaces';

export interface BuildingState extends EntityState<Building> {
    selected: Building | null;
    searchResults: Building[] | null;
    searchTerm: string;
    showOverview: boolean;
    editBuildingShape: boolean;
    showDetails: boolean;
    classification: number | null;
    classifications: BuildingClassification[];
    analytics: BuildingAnalytics | null;
    sortType: BuildingSortType;
    sortDirection: number;
    loaded: boolean;
    loading: boolean;
    loadingBuildingList: boolean;
}
export const adapter = createEntityAdapter<Building>();
export const initialState: BuildingState = adapter.getInitialState({
    selected: null,
    searchResults: [],
    searchTerm: '',
    showOverview: false,
    showDetails: false,
    editBuildingShape: false,
    classification: null,
    classifications: [],
    analytics: null,
    sortType: BuildingSortType.Occupancy,
    sortDirection: -1,
    loaded: false,
    loading: false,
    loadingBuildingList: false,
});
