import { Building } from '@map/models';
import { createSelector } from '@ngrx/store';
import { RootState } from '..';
import { adapter, BuildingState } from './building.state';

export const selectFeature = (state: RootState) => state.buildings;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectFeature);

export const selectLoaded = createSelector(selectFeature, (state: BuildingState) => state.loaded);

export const selectClassifications = createSelector(selectFeature, (state: BuildingState) => state.classifications);

export const selectAnalytics = createSelector(selectFeature, (state: BuildingState) => state.analytics);

export const selectEditingShape = createSelector(selectFeature, (state: BuildingState) => state.editBuildingShape);

export const selectLoading = createSelector(selectFeature, (state: BuildingState) => state.loading);

export const selectLoadingBuildingList = createSelector(selectFeature, (state: BuildingState) => state.loadingBuildingList);

export const selectSearchResults = createSelector(selectFeature, (state: BuildingState) => state.searchResults);

export const selectSearchTerm = createSelector(selectFeature, (state: BuildingState) => state.searchTerm);

export const selectSelectedBuilding = createSelector(selectFeature, (state: BuildingState) => state.selected);

export const selectShowOverview = createSelector(selectFeature, (state: BuildingState) => state.showOverview);

export const selectClassification = createSelector(selectFeature, (state: BuildingState) => state.classification);

export const selectShowDetails = createSelector(selectFeature, (state: BuildingState) => state.showDetails);

export const selectSortType = createSelector(selectFeature, (state: BuildingState) => state.sortType);

export const selectSortDirection = createSelector(selectFeature, (state: BuildingState) => state.sortDirection);

export const selectByLocationId = ({ locationId }: { locationId: number }) =>
    createSelector(selectAll, (state: any) => {
        return state.filter((x: Building) => x.locationId === locationId);
    });
