import { createReducer, on } from '@ngrx/store';
import { adapter, initialState } from './location.state';
import * as Actions from './location.actions';

export const locationReducer = createReducer(
    initialState,
    on(Actions.getAll, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getAllSuccess, (state, { locations }) => ({
        ...adapter.setAll(locations, state),
        loading: false,
        loaded: true,
    })),
    on(Actions.getAllFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.get, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getSuccess, (state, { location }) => {
        return adapter.upsertOne(location, {
            ...state,
            loading: false,
        });
    }),
    on(Actions.getFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.select, (state) => {
        return {
            ...state,
            loading: true,
            showOverview: false,
        };
    }),
    on(Actions.selectSuccess, (state, { location }) => ({
        ...state,
        selected: { ...location },
        loading: false,
    })),
    on(Actions.selectFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    })
);
