import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { FloorPlan } from '@map/models';
import { forkJoin, of } from 'rxjs';
import * as FloorPlanActions from './floor-plan.actions';
import * as FloorPlanSelectors from './floor-plan.selectors';
import { RootState } from '..';
import { Store } from '@ngrx/store';
import { FloorPlanService } from '@map/services/floor-plan.service';
import { Update } from '@ngrx/entity';

@Injectable({
    providedIn: 'root',
})
export class FloorPlanEffects {
    constructor(private actions$: Actions, private store: Store<RootState>, private floorPlanService: FloorPlanService) {}

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.getAll),
            withLatestFrom(this.store.select(FloorPlanSelectors.selectLoaded), this.store.select(FloorPlanSelectors.selectAll)),
            switchMap(([_, loaded, floorPlans]) => {
                if (loaded) {
                    return of(FloorPlanActions.getAllSuccess({ floorPlans }));
                } else {
                    return this.floorPlanService.getAll<FloorPlan[]>().pipe(
                        map((updatedFloorPlans) => FloorPlanActions.getAllSuccess({ floorPlans: updatedFloorPlans })),
                        catchError(() => of(FloorPlanActions.getAllFailed()))
                    );
                }
            })
        )
    );

    getImageUrls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.getImageUrls),
            mergeMap(({ floorPlanIds }) => {
                const requests = floorPlanIds.map((floorPlanId) => this.floorPlanService.get<FloorPlan>(floorPlanId));
                return forkJoin(requests);
            }),
            map((floorPlans: FloorPlan[]) => FloorPlanActions.getImageUrlsSuccess({ floorPlans })),
            catchError(() => of(FloorPlanActions.getImageUrlsFailed()))
        )
    );

    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.get),
            mergeMap(({ id }) =>
                this.floorPlanService.get<FloorPlan>(id).pipe(
                    map((floorPlan: FloorPlan) => FloorPlanActions.getSuccess({ floorPlan })),
                    catchError(() => of(FloorPlanActions.getFailed()))
                )
            )
        )
    );

    select$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.select),
            withLatestFrom(this.store.select(FloorPlanSelectors.selectEntities)),
            switchMap(([{ id }, floorPlans]) => {
                const floorPlan = floorPlans[id];
                if (floorPlan && floorPlan.imageUrl) {
                    return of(
                        FloorPlanActions.selectSuccess({
                            floorPlan,
                        })
                    );
                } else {
                    return this.floorPlanService
                        .get<FloorPlan>(id)
                        .pipe(map((floorPlanFromServer) => FloorPlanActions.selectSuccess({ floorPlan: floorPlanFromServer })));
                }
            }),
            catchError(() => of(FloorPlanActions.selectFailed()))
        )
    );

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.search),
            withLatestFrom(this.store.select(FloorPlanSelectors.selectAll)),
            switchMap(([{ term }, floorPlans]) => {
                const searchResults = floorPlans.filter((entity: FloorPlan) => entity.title?.toLowerCase().includes(term.toLowerCase()));
                return of(
                    FloorPlanActions.searchSuccess({
                        searchResults,
                    })
                );
            }),
            catchError(() => of(FloorPlanActions.searchFailed()))
        )
    );

    add$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.add),
            mergeMap(({ addFloorPlanDto }) =>
                this.floorPlanService.create<FloorPlan>(addFloorPlanDto.floorPlan).pipe(
                    concatMap((floorPlan: any) => {
                        // We need to create a new FloorPlan object because the server returns a different object
                        floorPlan = new FloorPlan(floorPlan);
                        return floorPlan.id
                            ? this.floorPlanService
                                  .uploadImage(floorPlan.id, addFloorPlanDto.image)
                                  .pipe(map(() => FloorPlanActions.addSuccess({ floorPlan })))
                            : of(FloorPlanActions.addFailed());
                    }),
                    catchError(() => of(FloorPlanActions.addFailed()))
                )
            )
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.update),
            concatMap(({ updateFloorPlanDto }) => {
                return this.floorPlanService.patch(updateFloorPlanDto.floorPlan.id, updateFloorPlanDto.floorPlan).pipe(
                    concatMap(() => {
                        return updateFloorPlanDto.image
                            ? this.floorPlanService.uploadImage(updateFloorPlanDto.floorPlan.id, updateFloorPlanDto.image)
                            : of(null);
                    }),
                    map(() => {
                        return {
                            id: updateFloorPlanDto.floorPlan.id,
                            changes: updateFloorPlanDto.floorPlan,
                        };
                    }),
                    map((updatedFloorPlan: Update<FloorPlan>) => FloorPlanActions.updateSuccess({ floorPlan: updatedFloorPlan })),
                    catchError((error) => of(FloorPlanActions.updateFailed()))
                );
            })
        )
    );

    remove$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FloorPlanActions.remove),
            mergeMap(({ id }) =>
                this.floorPlanService.delete(id).pipe(
                    map(() => {
                        return FloorPlanActions.removeSuccess({ id });
                    }),
                    catchError((error) => of(FloorPlanActions.removeFailed()))
                )
            )
        )
    );
}
