import { Occupancy } from '@map/models';
import { groupBy } from 'lodash';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
export default class Helpers {
    static getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];

    static stringIsNumber = (value: any) => isNaN(Number(value)) === false;

    static filterArrayBy<T>(array: T[], parameter: string, value: string, includesParameter: boolean): T[] {
        if (array) {
            return array?.filter((d: any) => {
                const getValue = Helpers.getKeyValue(parameter)(d);
                if (getValue) {
                    return includesParameter
                        ? getValue.toLowerCase().includes(value.toLowerCase())
                        : !getValue.toLowerCase().includes(value.toLowerCase());
                }
            });
        }
        return [];
    }

    static formatDateToJSON(date: Date, addGMTOffset: boolean = true): string {
        return moment.parseZone(date).utc().format();
        const offset = addGMTOffset ? 60000 : 1;
        return new Date(date.getTime() - date.getTimezoneOffset() * offset).toJSON();
    }

    static getTenantTimeZoneOffset(): number {
        return moment(new Date()).tz(environment.timeZone).utcOffset() / 60;
    }

    /** Converts the date from UTC to the timezone of the environment */
    static utcDateToEnvironmentDate(date: Date | string): Date {
        return new Date(moment.tz(date, 'UTC').tz(environment.timeZone).format('YYYY/MM/DD HH:mm:ss'));
    }
    static utcDateToLocalDate(date: Date | string): Date {
        return new Date(moment.tz(date, 'UTC').tz(moment.tz.guess()).format('YYYY/MM/DD HH:mm:ss'));
    }

    static isLive(date: Date): boolean {
        return moment(date).isSame(new Date(), 'date');
    }

    // Take the raw occupancy data and get the percentage
    // of the maximum reading, and add a day-of-week string.
    static mapOccupancyDataByHour(occupancyDataResponse: Occupancy): { [id: string]: { name: string; data: any[] } } {
        let occupancyDataArray = occupancyDataResponse.occupancyStats as any;
        // Group data by the hour
        occupancyDataArray = Object.values(groupBy(occupancyDataArray, (i) => i.hour));
        // Create empty dictionary to store data
        const occupancyData: { [id: string]: any } = {};

        // Loop through each set of hourly data
        for (const key in occupancyDataArray) {
            if (key) {
                // Convert to result object
                const dataArray: any = occupancyDataArray[key];
                occupancyData[key] = { name: key, data: dataArray };

                // Go through each data point and generate
                // the occupancy percentage based on maxOccupancyHistoric.
                for (const dayKey in dataArray) {
                    if (dayKey) {
                        const dayRecord = dataArray[dayKey];

                        // Calculate the occupancy percentage
                        const occPercent =
                            dayRecord.averageOccupancy > 0
                                ? (dayRecord.averageOccupancy / occupancyDataResponse.maxOccupancyHistoric) * 100
                                : 0;

                        // Get date object to fetch day-of-week string.
                        const day = new Date(dayRecord.year, dayRecord.month - 1, dayRecord.day, dayRecord.hour).getDay();

                        // Update dictionary value
                        dataArray[dayKey] = {
                            x: moment().day(day).format('ddd'),
                            y: occPercent,
                            hour: key,
                            deviceCount: dayRecord.deviceCount,
                            day,
                        };
                    }
                }
            }
        }
        return occupancyData;
    }

    static mapOccupancyDataByDay(occupancyDataResponse: Occupancy): { [id: string]: { name: string; data: any[] } } {
        let occupancyDataArray = occupancyDataResponse.occupancyStats as any;
        // Group data by the day
        occupancyDataArray = Object.values(groupBy(occupancyDataArray, (i) => i.day));
        // Create empty dictionary to store data
        const occupancyData: { [id: string]: any } = {};
        // Loop through each set of hourly data
        occupancyDataArray.forEach((dayData: any) => {
            const day = dayData[0].day;
            const month = dayData[0].month;
            const year = dayData[0].year;
            const dataArray: any = dayData;
            console.log({ ...dayData });
            // occupancyData[day] = { name: moment().day(day).format('ddd'), data: dataArray };
            const date = new Date(year, month - 1, day);

            occupancyData[day] = { name: date.toLocaleDateString(), data: dataArray };
            // Go through each data point and generate
            // the occupancy percentage based on maxOccupancyHistoric.
            for (const dayKey in dataArray) {
                if (dayKey) {
                    const dayRecord = dataArray[dayKey];
                    // Calculate the occupancy percentage
                    const occPercent =
                        dayRecord.averageOccupancy > 0
                            ? (dayRecord.averageOccupancy / occupancyDataResponse.maxOccupancyHistoric) * 100
                            : 0;

                    // Get date object to fetch day-of-week string.
                    const date = new Date(dayRecord.year, dayRecord.month - 1, dayRecord.day, dayRecord.hour);
                    const dayOfWeek = date.getDay();

                    // Update dictionary value
                    dataArray[dayKey] = {
                        x: dayRecord.hour.toString(),
                        y: Math.random() * 100,
                        // y: occPercent,
                        day: dayRecord.day,
                        hour: dayRecord.hour,
                        month: dayRecord.month,
                        year: dayRecord.year,
                        deviceCount: dayRecord.deviceCount,
                        dayOfWeek,
                        date,
                    };
                }
            }
        });
        return occupancyData;
    }
    static convertOccupancyToChartData(occupancy: Occupancy, type: 'hourly' | 'daily'): any {
        if (type === 'hourly') {
            return Helpers.convertOccupancyToChartDataByHour(occupancy, type);
        } else {
            return Helpers.convertOccupancyToChartDataByDay(occupancy, type);
        }
    }

    static convertOccupancyToChartDataByHour(occupancy: Occupancy, type: 'hourly' | 'daily'): any {
        const occupancyData = Helpers.mapOccupancyDataByHour(occupancy);
        const occupancyDataValues = [...Object.values(occupancyData)];

        type RowType = { hour: string; x: string; y: number; day: number };
        type ChartRowType = { name: string; data: RowType[] };

        const indexedData: Array<Array<RowType>> = new Array(24);

        // reduce the raw data to have one entry per hour per day,
        // taking the average of each day-hour seen.
        occupancyDataValues.forEach((entry) => {
            const dayCount: { [id: string]: number } = {};
            const transformedData = entry.data.reduce((prev, curr, index) => {
                const dayEntry = prev.find((pre: any) => pre.x === curr.x);
                if (dayEntry) {
                    const recCount = dayCount[curr.x] || 0;
                    // average the entry as we go along
                    dayEntry.y = (recCount * dayEntry.y + curr.y) / (recCount + 1);
                } else {
                    prev.push(curr);
                    dayCount[curr.x] = 0;
                }
                dayCount[curr.x] += 1;
                return prev;
            }, [] as RowType[]);

            transformedData.forEach((row: any) => {
                if (indexedData[row.hour] === undefined) {
                    indexedData[row.hour] = new Array(7);
                }
                indexedData[row.hour][row.day] = row;
            });
        });

        // generate final table, filling in any gaps with
        // a value to be rendered as 'no data'.
        const chartData: Array<ChartRowType> = new Array(24);
        for (let hour = 0; hour < 24; hour += 1) {
            for (let day = 0; day < 7; day += 1) {
                // fill in "no data" fields
                if (indexedData[hour] === undefined) {
                    indexedData[hour] = new Array(7);
                }
                if (indexedData[hour][day] === undefined) {
                    indexedData[hour][day] = {
                        hour: hour.toString(),
                        x: moment().day(day).format('ddd'),
                        y: -999, // neg value shows as 'no data'
                        day,
                    };
                }
            }

            chartData[hour] = {
                name: hour.toString(),
                data: indexedData[hour],
            };
        }
        return chartData.reverse();
    }

    static convertOccupancyToChartDataByDay(occupancy: Occupancy, type: 'hourly' | 'daily'): any {
        console.log('occupancy', occupancy);
        const occupancyData = Helpers.mapOccupancyDataByDay(occupancy);
        console.log('occupancyData', occupancyData);
        const occupancyDataValues = [...Object.values(occupancyData)];

        console.log('occupancyDataValues', occupancyDataValues);
        occupancyDataValues.forEach((entry) => {
            if (entry.data.length === 0) {
                return;
            }
            const day = entry.data[0].day;
            const month = entry.data[0].month;
            const year = entry.data[0].year;
            const dayOfWeek = entry.data[0].dayOfWeek;
            console.log('day', day, entry.data[0]);
            for (let hour = 0; hour < 24; hour += 1) {
                // Check if this hour is in the data
                const hourData = entry.data.find((data: any) => data.hour === hour);
                if (!hourData) {
                    // If not, create a new entry
                    entry.data.push({
                        x: hour.toString(),
                        y: -999,
                        date: new Date(year, month - 1, day, hour),
                        day,
                        hour,
                        dayOfWeek,
                        month,
                    });
                }
            }
            // Sort the data by hour
            entry.data.sort((a: any, b: any) => a.hour - b.hour);
            console.log('entry', entry);
        });
        console.log('final occupancyDataValues', occupancyDataValues);
        // Sort the data by day
        occupancyDataValues.sort((a: any, b: any) => b.data[0].date - a.data[0].date);
        return occupancyDataValues;

        type RowType = { hour: string; x: string; y: number; day: number; date: Date };
        type ChartRowType = { name: string; data: RowType[] };

        const indexedData: Array<Array<RowType>> = new Array(24);

        // reduce the raw data to have one entry per hour per day,
        // taking the average of each day-hour seen.
        occupancyDataValues.forEach((entry) => {
            const dayCount: { [id: string]: number } = {};
            const transformedData = entry.data.reduce((prev, curr, index) => {
                const dayEntry = prev.find((pre: any) => pre.x === curr.x);
                if (dayEntry) {
                    const recCount = dayCount[curr.x] || 0;
                    // average the entry as we go along
                    dayEntry.y = (recCount * dayEntry.y + curr.y) / (recCount + 1);
                } else {
                    prev.push(curr);
                    dayCount[curr.x] = 0;
                }
                dayCount[curr.x] += 1;
                return prev;
            }, [] as RowType[]);

            transformedData.forEach((row: any) => {
                if (indexedData[row.hour] === undefined) {
                    indexedData[row.hour] = new Array(7);
                }
                indexedData[row.hour][row.day] = row;
            });
        });

        // generate final table, filling in any gaps with
        // a value to be rendered as 'no data'.
        const chartData: Array<ChartRowType> = new Array(24);
        for (let hour = 0; hour < 24; hour += 1) {
            for (let day = 0; day < 7; day += 1) {
                // fill in "no data" fields
                if (indexedData[hour] === undefined) {
                    indexedData[hour] = new Array(7);
                }
                if (indexedData[hour][day] === undefined) {
                    // Determine the date for the missing data
                    let prevDay = day - 1;
                    let prevHour = hour;
                    if (prevDay < 0) {
                        prevDay = 6;
                        prevHour = hour - 1 < 0 ? 23 : hour - 1;
                    }
                    const prevData = indexedData[prevHour][prevDay];
                    const date = new Date(prevData.date);
                    date.setDate(date.getDate() + 1);
                    date.setHours(hour);
                    // Create the missing data
                    indexedData[hour][day] = {
                        hour: hour.toString(),
                        x: `${moment(date).format('ddd')} ${moment(date).format('MM/DD/YYYY')}`,
                        y: -999,
                        day,
                        date,
                    };
                }
            }

            chartData[hour] = {
                name: hour.toString(),
                data: indexedData[hour],
            };
        }
        console.log('chartData', chartData);
        return chartData.reverse();
    }
}
