import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccessPoint } from '@map/models';
import { concat, of } from 'rxjs';
import * as AccessPointActions from './access-point.actions';
import * as AccessPointSelectors from './access-point.selectors';
import { AccessPointService } from '@map/services/access-point.service';
import { Store } from '@ngrx/store';
import { RootState } from '..';

@Injectable({
    providedIn: 'root',
})
export class AccessPointEffects {
    constructor(private actions$: Actions, private store: Store<RootState>, private accessPointService: AccessPointService) {}

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccessPointActions.getAll),
            withLatestFrom(this.store.select(AccessPointSelectors.selectLoaded), this.store.select(AccessPointSelectors.selectAll)),
            switchMap(([_, loaded, accessPoints]) => {
                if (loaded) {
                    return of(AccessPointActions.getAllSuccess({ accessPoints }));
                } else {
                    return this.accessPointService.getAll<AccessPoint[]>().pipe(
                        map((accessPoints: AccessPoint[]) => AccessPointActions.getAllSuccess({ accessPoints })),
                        catchError(() => of(AccessPointActions.getAllFailed()))
                    );
                }
            })
            // mergeMap(() =>
            //     this.accessPointService.getAll<AccessPoint[]>('+Building+BuildingFloor').pipe(
            //         map((accessPoints: AccessPoint[]) => {
            //             accessPoints.forEach((accessPoint) => {
            //                 accessPoint.createdAt = new Date(accessPoint.createdAt);
            //             });
            //             return accessPoints;
            //         }),
            //         map((accessPoints: AccessPoint[]) => AccessPointActions.getAllSuccess({ accessPoints })),
            //         catchError(() => of(AccessPointActions.getAllFailed()))
            //     )
            // )
        )
    );

    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccessPointActions.get),
            mergeMap(({ id }) =>
                this.accessPointService.get<AccessPoint>(id).pipe(
                    map((accessPoint: AccessPoint) => AccessPointActions.getSuccess({ accessPoint })),
                    catchError(() => of(AccessPointActions.getFailed()))
                )
            )
        )
    );

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccessPointActions.search),
            withLatestFrom(this.store.select(AccessPointSelectors.selectAll)),
            switchMap(([{ term }, accessPoints]) => {
                const searchResults = accessPoints.filter((entity) => entity.name.toLowerCase().includes(term.toLowerCase()));
                return of(
                    AccessPointActions.searchSuccess({
                        searchResults,
                    })
                );
            }),
            catchError(() => of(AccessPointActions.searchFailed()))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccessPointActions.update),
            mergeMap(({ accessPoint }) =>
                this.accessPointService.update<AccessPoint>(accessPoint.id, accessPoint.changes).pipe(
                    mergeMap(() =>
                        concat(
                            of(AccessPointActions.getAll()),
                            of(AccessPointActions.updateSuccess({ accessPoint })),
                            of(AccessPointActions.closeFormModal())
                        )
                    ),
                    catchError(() => of(AccessPointActions.updateFailed(), AccessPointActions.closeFormModal()))
                )
            )
        )
    );
}
