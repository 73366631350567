import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { DateRange } from '@map/models/date-range.model';
import { isNullOrUndefined } from 'util';

@Injectable({
    providedIn: 'root',
})
export class MapService {
    public playbackSliderMaxAmtOrig = 10;
    public playbackSliderMaxAmt = 10;

    public mapPosition$ = signal<number[] | null>(null);

    private overviewPanelHeight: BehaviorSubject<number> = new BehaviorSubject(0);
    overviewPanelHeight$ = this.overviewPanelHeight.asObservable();

    private mapDateTime: BehaviorSubject<Date> = new BehaviorSubject(new Date());
    mapDateTime$ = this.mapDateTime.asObservable();

    // The datetime that is currently displaying data (eg. when scrubbing a range of devices)
    private playbackRange: BehaviorSubject<DateRange> = new BehaviorSubject(new DateRange());
    playbackRange$ = this.playbackRange.asObservable();

    private isOverviewExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    isOverviewExpanded$ = this.isOverviewExpanded.asObservable();

    private isAnalyticsExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isAnalyticsExpanded$ = this.isAnalyticsExpanded.asObservable();

    private isPlaying: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isPlaying$ = this.isPlaying.asObservable();

    private isPlaybackLive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    isPlaybackLive$ = this.isPlaybackLive.asObservable();

    private playbackSliderValue: BehaviorSubject<number> = new BehaviorSubject(0);
    playbackSliderValue$ = this.playbackSliderValue.asObservable();

    private playbackSliderMax: BehaviorSubject<number> = new BehaviorSubject(this.playbackSliderMaxAmt);
    playbackSliderMax$ = this.playbackSliderMax.asObservable();

    private playbackSpeed: BehaviorSubject<number> = new BehaviorSubject(1);
    playbackSpeed$ = this.playbackSpeed.asObservable();

    stopPlay$: Subject<void> = new Subject();

    centerMap$: Subject<void> = new Subject();

    private zoomIn: Subject<void> = new Subject();
    zoomIn$ = this.zoomIn.asObservable();
    private zoomOut: Subject<void> = new Subject();
    zoomOut$ = this.zoomOut.asObservable();

    private showFloorPlans: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    showFloorPlans$ = this.showFloorPlans.asObservable();

    private showDevices: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    showDevices$ = this.showDevices.asObservable();

    private showAccessPoints: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showAccessPoints$ = this.showAccessPoints.asObservable();

    private showClusters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showClusters$ = this.showClusters.asObservable();

    private showHeatmap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    showHeatmap$ = this.showHeatmap.asObservable();

    private filterSSID: BehaviorSubject<[]> = new BehaviorSubject([]);
    filterBySSID$ = this.filterSSID.asObservable();

    private isDrawing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isDrawing$ = this.isDrawing.asObservable();
    private deleteDrawing: Subject<void> = new Subject();
    deleteDrawing$ = this.deleteDrawing.asObservable();

    constructor() {}

    updateOverviewPanelHeight(newHeight: number): void {
        this.overviewPanelHeight.next(newHeight);
    }

    mapZoomIn(): void {
        this.zoomIn.next();
    }
    mapZoomOut(): void {
        this.zoomOut.next();
    }
    centerMap(): void {
        this.centerMap$.next();
    }
    toggleOverview(isExpanded?: boolean): void {
        if (isExpanded) {
            this.isOverviewExpanded.next(isExpanded);
        } else {
            this.isOverviewExpanded.next(!this.isOverviewExpanded.value);
        }
    }
    toggleAnalytics(isExpanded?: boolean): void {
        if (isExpanded) {
            this.isAnalyticsExpanded.next(isExpanded);
        } else {
            this.isAnalyticsExpanded.next(!this.isAnalyticsExpanded.value);
        }
    }

    startPlayback(): void {
        this.stopPlay$.next();
        this.isPlaying.next(true);
    }
    stopPlayback(): void {
        this.stopPlay$.next();
        this.isPlaying.next(false);
    }
    togglePlayback(): void {
        this.stopPlay$.next();
        this.isPlaying.next(!this.isPlaying.value);
    }
    setPlaybackLive(isLive: boolean): void {
        this.stopPlay$.next();
        this.isPlaybackLive.next(isLive);
        if (isLive === true) {
            this.updateMapDateTime(new Date());
            this.updatePlaybackRange(new DateRange(new Date()));
            this.resetPlaybackSlider();
            this.resetPlaybackSliderMax();
        }
    }
    toggleLive(): void {
        this.setPlaybackLive(!this.isPlaybackLive.value);
    }

    setShowFloorPlans(show: boolean): void {
        this.showFloorPlans.next(show);
    }
    setShowDevices(show: boolean): void {
        this.showDevices.next(show);
    }
    setShowAccessPoints(show: boolean): void {
        this.showAccessPoints.next(show);
    }
    setShowClusters(show: boolean): void {
        this.showClusters.next(show);
    }
    setShowHeatmap(show: boolean): void {
        this.showHeatmap.next(show);
    }

    setDrawing(value: boolean): void {
        this.isDrawing.next(value);
    }
    onDeleteDrawing(): void {
        this.deleteDrawing.next();
    }
    resetPlaybackSlider(): void {
        this.updatePlaybackSlider(0);
    }
    updatePlaybackSlider(value: number): void {
        if (value !== this.playbackSliderValue.value) {
            this.playbackSliderValue.next(value);
            if (this.playbackRange.value.end) {
                this.updateDisplayedMapDateTime(moment(this.playbackRange.value.start).add(value, 'minute').toDate());
            } else {
                this.updateDisplayedMapDateTime(
                    moment(this.playbackRange.value.start).subtract(this.playbackSliderMax.value, 'minute').add(value, 'minute').toDate()
                );
            }
        }
    }
    updatePlaybackSpeed(value: number): void {
        if (value !== this.playbackSpeed.value) {
            this.playbackSpeed.next(value);
        }
    }

    resetPlaybackSliderMax(): void {
        this.updatePlaybackSliderMax(this.playbackSliderMaxAmtOrig);
    }
    updatePlaybackSliderMax(value: number): void {
        if (value !== this.playbackSliderMax.value) {
            this.playbackSliderMax.next(value);
        }
    }

    updateMapDateTimeToLive(): void {
        this.updateMapDateTime(new Date());
    }

    updatePlaybackRange(dateRange: DateRange, limitToSliderMax: boolean = true): DateRange {
        // Don't allow the end date to be after the start date
        if (dateRange.end && dateRange.end > new Date()) {
            dateRange.end = new Date();
        }
        // Get the difference in minutes between the start and end times.
        let diffInMinutes = moment(dateRange.end).diff(moment(dateRange.start), 'minutes');
        if (limitToSliderMax && diffInMinutes > this.playbackSliderMaxAmt) {
            diffInMinutes = this.playbackSliderMaxAmt;
        } else if (diffInMinutes <= 0) {
            diffInMinutes = 1;
        }
        this.updatePlaybackSliderMax(diffInMinutes);
        // Update the playback range
        this.playbackRange.next(dateRange);
        // Update the displayed map date time
        this.updateMapDateTime(dateRange.start);
        return dateRange;
    }

    updateMapDateTime(date: Date): void {
        const curDate = moment(new Date()).seconds(0).milliseconds(0).toDate();
        const newDate = moment(date).seconds(0).milliseconds(0).toDate();
        if (newDate < curDate) {
            this.isPlaybackLive.next(false);
        }
        if (newDate <= curDate) {
            this.updateDisplayedMapDateTime(newDate);
        }
    }
    updateDisplayedMapDateTime(newDate: Date): void {
        this.mapDateTime.next(newDate);
    }

    filterBySSID(ssid: []): void {
        this.filterSSID.next(ssid);
    }
}
