import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@shared/services/base.service';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Report } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ReportService extends BaseService {
    constructor(private httpClient: HttpClient) {
        super('Reports', httpClient);
    }

    getAllByUserId(userId: number): Observable<Report[]> {
        return this.httpClient.get<Report[]>(`${this.apiUrl}/user/${userId}`).pipe(
            retry(2),
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }
}
