import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Occupancy, Region } from '@map/models';

export interface RegionState extends EntityState<Region> {
    selected: Region | null;
    searchResults: Region[] | null;
    searchTerm: string;
    buildingRegions: Region[];
    editShape: boolean;
    loaded: boolean;
    loading: boolean;
    occupancy: Occupancy | null;
    occupancyList: Occupancy[] | null;
}
export const adapter = createEntityAdapter<Region>();
export const initialState: RegionState = adapter.getInitialState({
    selected: null,
    searchResults: [],
    searchTerm: '',
    buildingRegions: [],
    loaded: false,
    editShape: false,
    loading: false,
    occupancy: null,
    occupancyList: null,
});
