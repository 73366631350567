import { createReducer, on } from '@ngrx/store';
import { adapter, initialState } from './building.state';
import * as Actions from './building.actions';

export const buildingReducer = createReducer(
    initialState,
    on(Actions.getAll, (state) => {
        return {
            ...state,
            loading: true,
            loadingBuildingList: true,
        };
    }),
    on(Actions.getAllSuccess, (state, { buildings }) => ({
        ...adapter.setAll(buildings, state),
        loadingBuildingList: false,
        loading: false,
        loaded: true,
    })),
    on(Actions.getAllFailed, (state) => {
        return {
            ...state,
            loading: false,
            loadingBuildingList: false,
        };
    }),
    on(Actions.getAllCached, (state) => {
        return {
            ...state,
            loading: true,
            loadingBuildingList: true,
        };
    }),
    on(Actions.getBuildingClassifications, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getBuildingClassificationsSuccess, (state, { classifications }) => {
        return {
            ...state,
            classifications,
            loading: false,
        };
    }),
    on(Actions.getBuildingClassificationsFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.get, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getSuccess, (state, { building }) => {
        if (!state.ids.some((b) => b === building.id)) {
            return adapter.upsertOne(building, {
                ...state,
                loading: false,
            });
        }
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.getFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.setSearchTerm, (state, { term }) => {
        return {
            ...state,
            searchTerm: term,
        };
    }),
    on(Actions.search, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.searchSuccess, (state, { searchResults }) => {
        return {
            ...state,
            searchResults,
            loading: false,
        };
    }),
    on(Actions.searchFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.clearSearchResults, (state) => {
        return {
            ...state,
            searchResults: null,
        };
    }),
    on(Actions.update, (state) => {
        return {
            ...state,
            loading: true,
            showOverview: false,
        };
    }),
    on(Actions.updateSuccess, (state, { building }) => {
        return adapter.updateOne(building, {
            ...state,
            loading: false,
        });
    }),
    on(Actions.updateFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.select, (state) => {
        return {
            ...state,
            loading: true,
            showOverview: false,
        };
    }),
    on(Actions.selectSuccess, Actions.selectByMapboxIdSuccess, (state, { building }) => ({
        ...state,
        selected: { ...building },
        loading: false,
    })),
    on(Actions.selectFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.deselect, (state) => {
        return {
            ...state,
            selected: null,
            showOverview: false,
            showDetails: false,
        };
    }),
    on(Actions.setClassification, (state, { classification }) => ({
        ...state,
        classification,
    })),
    on(Actions.setSortType, (state, { sortType }) => ({
        ...state,
        sortType,
    })),
    on(Actions.setSortDirection, (state, { direction }) => ({
        ...state,
        sortDirection: direction,
    })),
    on(Actions.getAnalytics, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.getAnalyticsSuccess, (state, { analytics }) => {
        return {
            ...state,
            analytics,
            loading: false,
        };
    }),
    on(Actions.getAnalyticsFailed, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(Actions.selectByMapboxId, (state) => {
        return {
            ...state,
            loading: true,
            showOverview: false,
        };
    }),
    on(Actions.showOverview, (state) => {
        return {
            ...state,
            showOverview: true,
        };
    }),
    on(Actions.hideOverview, (state) => {
        return {
            ...state,
            showOverview: false,
        };
    }),
    on(Actions.showDetails, (state) => {
        return {
            ...state,
            showOverview: false,
            showDetails: true,
        };
    }),
    on(Actions.hideDetails, (state) => {
        return {
            ...state,
            showDetails: false,
        };
    }),
    on(Actions.editBuildingShape, (state) => {
        return {
            ...state,
            loading: true,
            editBuildingShape: true,
        };
    }),
    on(Actions.cancelEditBuildingShape, (state) => {
        return {
            ...state,
            editBuildingShape: false,
        };
    }),
    on(Actions.updateBuildingPolygon, Actions.updateBuildingPolygonMap, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(Actions.updateBuildingPolygonSuccess, (state, { building }) => {
        return adapter.upsertOne(building, {
            ...state,
            editBuildingShape: false,
            loading: false,
        });
    }),
    on(Actions.updateBuildingPolygonFailed, (state) => {
        return {
            ...state,
            editBuildingShape: false,
            loading: false,
        };
    })
);
