import { FloorPlan, ActiveType } from '@map/models';
import { createSelector } from '@ngrx/store';
import { RootState } from '..';
import { adapter, FloorPlanState } from './floor-plan.state';

export const selectFeature = (state: RootState) => state.floorPlans;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state: FloorPlanState) => state.loading);

export const selectLoaded = createSelector(selectFeature, (state: FloorPlanState) => state.loaded);

export const selectSelectedFloorPlan = createSelector(selectFeature, (state: FloorPlanState) => state.selected);

export const selectSearchResults = createSelector(selectFeature, (state: FloorPlanState) => state.searchResults);

export const selectSearchTerm = createSelector(selectFeature, (state: FloorPlanState) => state.searchTerm);

export const selectByLocationId = (locationId: number) =>
    createSelector(selectAll, (state: any) => {
        return state.filter((x: FloorPlan) => x.locationId === locationId);
    });

export const selectEditingShape = createSelector(selectFeature, (state: FloorPlanState) => state.editShape);
