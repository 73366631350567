import { AccessPoint } from '@map/models';
import { createSelector } from '@ngrx/store';
import { RootState } from '..';
import { adapter, AccessPointState } from './access-point.state';

export const selectFeature = (state: RootState) => state.accessPoints;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectFeature);

export const selectLoading = createSelector(selectFeature, (state: AccessPointState) => state.loading);

export const selectLoaded = createSelector(selectFeature, (state: AccessPointState) => state.loaded);

export const selectSelectedAccessPoint = createSelector(selectFeature, (state: AccessPointState) => state.selected);

export const selectByBuildingId = (buildingId: number) =>
    createSelector(selectAll, (state: any) => {
        return state.filter((x: AccessPoint) => x.floor?.buildingId === buildingId);
    });

export const selectAccessPointsByStatus = (status: string) =>
    createSelector(selectAll, (state: any) => {
        return state.filter((x: AccessPoint) => x.status === status);
    });

export const selectSearchResults = createSelector(selectFeature, (state: AccessPointState) => state.searchResults);

export const selectSearchTerm = createSelector(selectFeature, (state: AccessPointState) => state.searchTerm);
