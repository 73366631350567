import { Injectable } from '@angular/core';
import { FloorPlan } from '@map/models';
import { BaseService } from '@shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { catchError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FloorPlanService extends BaseService {
    constructor(private httpClient: HttpClient) {
        super('FloorPlans', httpClient);
    }

    uploadImage(floorPlanId: number, file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return this.httpClient.post(`${this.apiUrl}/${floorPlanId}/image`, formData).pipe(
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }

    mapResponseToObject<T>(response: any): T {
        const floorPlan = new FloorPlan(response) as any;
        return floorPlan;
    }
    mapArrayResponseToObject<T>(response: any): T {
        return response.map((responseJson: any) => new FloorPlan(responseJson));
    }
}
