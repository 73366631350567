import { Component } from '@angular/core';
import { MapService } from '@map/services/map.service';
import { BehaviorSubject, map, tap } from 'rxjs';

@Component({
    selector: 'app-map-layout',
    templateUrl: './map-layout.component.html',
    styleUrls: ['./map-layout.component.scss'],
})
export class MapLayoutComponent {
    isDrawing$ = this.mapService.isDrawing$.pipe(map((isDrawing) => ({ value: isDrawing })));

    constructor(private mapService: MapService) {}
}
