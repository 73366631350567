import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '@map/models';

export interface UserState extends EntityState<User> {
    self: User | null;
    selected: User | null;
    searchResults: User[] | null;
    searchTerm: string;
    loaded: boolean;
    loading: boolean;
}
export const adapter = createEntityAdapter<User>();
export const initialState: UserState = adapter.getInitialState({
    self: null,
    selected: null,
    searchResults: [],
    searchTerm: '',
    loaded: false,
    loading: false,
});
