import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
    InteractionStatus,
    EventMessage,
    EventType,
    AuthenticationResult,
    InteractionType,
    PopupRequest,
    RedirectRequest,
    AuthError,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { b2cPolicies } from './../../../b2c-config';

import * as UserActions from '@store/user/user.actions';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    title = 'airia';
    loginDisplay = false;

    private readonly destroying$ = new Subject<void>();
    loginSubscription: Subscription;

    get authenticated(): boolean {
        return this.authService.instance.getActiveAccount() ? true : false;
    }

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private router: Router,
        private store: Store<RootState>
    ) {
        this.loginSubscription = this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                )
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
                console.log('redirecting to map');
                this.router.navigate(['/map']);
            });

        this.msalBroadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
            .subscribe((result: EventMessage) => {
                console.log('Successfully logged in.');
            });
    }
    checkLoggedIn(): void {
        this.store.dispatch(UserActions.getSelf());
    }

    ngOnInit(): void {
        this.getLoginStatus();
    }

    async getLoginStatus(): Promise<void> {
        await this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) => msg.eventType !== EventType.LOGIN_SUCCESS && msg.eventType !== EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this.destroying$)
            )
            .subscribe((result: EventMessage) => {
                this.loginDisplay = false;
                const error: AuthError = result.error as AuthError;
                if (error?.errorCode === 'no_tokens_found') {
                    this.authService.logoutRedirect().subscribe();
                } else if (error?.errorMessage.indexOf('AADB2C90118') > -1) {
                    const resetPasswordFlowRequest = {
                        scopes: ['openid', 'profile'],
                        authority: b2cPolicies.authorities.resetPassword.authority,
                    };
                    this.authService.loginRedirect(resetPasswordFlowRequest);
                } else if (error?.errorMessage.indexOf('AADB2C90091') > -1) {
                    // Return to Login screen
                    this.authService.loginRedirect();
                } else if (error) {
                    console.log('error unaccounted for', result);
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.destroying$)
            )
            .subscribe(async (result) => {
                if (!this.authenticated) {
                    console.log('Not authenticated, redirect to login');
                    this.authService.loginRedirect();
                }
            });
    }

    setLoginDisplay(): void {
        this.loginDisplay = this.authService.instance.getAllAccounts().length === 0;
    }

    checkAndSetActiveAccount(): void {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest): void {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService
                    .loginPopup({
                        ...this.msalGuardConfig.authRequest,
                        ...userFlowRequest,
                    } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({
                    ...this.msalGuardConfig.authRequest,
                    ...userFlowRequest,
                } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    logout(): void {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.logoutPopup({
                mainWindowRedirectUri: '/',
            });
        } else {
            this.authService.logoutRedirect();
        }
    }

    editProfile(): void {
        const editProfileFlowRequest = {
            scopes: ['openid'],
            authority: b2cPolicies.authorities.editProfile.authority,
        };

        this.login(editProfileFlowRequest);
    }

    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
        this.loginSubscription?.unsubscribe();
    }
}
